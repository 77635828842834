import { mapEnumToOptions } from "@abp/ng.core";

export enum PstScanTarget {
    OutlookAttached = 1,
    AllLocalFixedDrives = 2,
    HomeDriveLocal = 4,
    HomeShareServer = 8,
    MappedNetworkDrives = 16,
    MappedNetworkDrivesWithUsername = 32
}
export const pstScanTargetOptions = mapEnumToOptions(PstScanTarget);
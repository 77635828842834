import { mapEnumToOptions } from "@abp/ng.core";

export enum PstScanExclusion {
    AllBusinessOneDrive = 1,
    AllExceptMainBusinessOneDrive = 2,
    LinkedFoldersInOneDrive = 4,
    ConsumerOneDrive = 8,
    OfflinePlaceholders = 16,
    AllPlaceholders = 32
}
export const pstScanExclusionOptions = mapEnumToOptions(PstScanExclusion);
